<template lang="pug">
Navbar
.padding-top.help
  .help-header.pricing.pb-5
    .fs-4.pricing-title.fw-bold.lh-sm.pb-2.text-center How can we help you?
    .container
      .row.height.d-flex.justify-content-center.align-items-center
        .col-md-6
          .search
            span.input-group-text
              img.ps-3(src="icons/search.svg")
              input.form-control(type='text' placeholder='Search Here' v-model="search")
.container.py-5
  BackHelp
  .row
    .col-md-12.py-5
      template(v-for="help in ContactList")
        .help-title.fs-5.fw-bold.pb-4 {{ help.title }}
        .help-details
          .description
            template(v-for="subhelp in help.description")
              p {{ subhelp.text }}
              .help-image.py-4(v-if="subhelp.imgType === true")
                img.img-fluid(:src="'/images/help/' + subhelp.img")
          .detail-explanation
            template(v-for="exp in help.explanation")
              .explanation-title.fs-5.fw-bold {{ exp.expTitle }}
              .explanation-description
                p {{ exp.expDesc }}
              .explanation-image.py-4(v-if="exp.imgType === true")
                img.img-fluid(:src="'/images/help/' + exp.img")
  BackHelp
Footer
</template>

<script>
import Navbar from "../../../components/TheNavbar";
import Footer from "../../../components/TheFooter";
import BackHelp from "../../../components/BackHelp";
export default {
  name: "ContactList",
  components: { Footer, Navbar, BackHelp },
  data() {
    return {
      ContactList: [
        {
          title: "2.1 How to import contact lists?",
          description: [
            {
              text: "On the client details screen, you can access all the information and actions related to a client or lead. From here you can view and edit your follow up date, groups, notes, and interaction timeline, contact the client, and edit or delete the client.",
              imgType: "yes",
              img: "lead details.png",
            },
            {
              text: "You can call, SMS, WhatsApp, email the client using the buttons at the top of the screen. If the relevant information isn't provided, the contact buttons will be greyed out.",
              imgType: "no",
            },
          ],
          explanation: [
            {
              expTitle: "FIRST TIME USER",
              expDesc:
                "You can choose to import from Phonebook , email contact or upload excel sheet for the 1st contact sync to Kloser.",
              imgType: true,
              img: "Kloser-Page-Empty-lead.png",
            },
            {
              expTitle: "EXISTING USER - Option 1",
              expDesc:
                "If you have few contacts to add, you can manually create or import them from the phonebook via the (+) floating icon on the lead page. You should be able to see the option of  a)import from phone book, b)import from email contact, c)Add contact manually after clicking the (+) button.",
              imgType: true,
              img: "option-1.jpg",
            },
            {
              expTitle: "Import contacts from phonebook:",
              expDesc: "1. Click “Allow” for Kloser to access your contacts",
              imgType: true,
              img: "allow-contact-kloser.jpeg",
            },
            {
              expTitle: "",
              expDesc:
                "2. Click “Import” after select contacts or click “Select all”",
              imgType: true,
              img: "75. Import phonebook_select all.png",
            },
            {
              expTitle: "",
              expDesc: "3. It will pop up a successful message and click “Ok”",
              imgType: false,
            },
            {
              expTitle: "Add contact manually:",
              expDesc:
                "1. Enter the information of person (Name, Mobile number, Email, Job Title, Company & Note) and Click “Save”",
              imgType: true,
              img: "add-contact-manually.jpg",
            },
            {
              expTitle: "Option 2 - Excel ( coming soon)",
              expDesc:
                "If you have many contacts to add, you can bulk import them from via excel file into your Kloser account. This option is only available on Kloser desktop website. You can all the contacts and can choose to skip or keep duplicate contacts that already exist in your account.",
              imgType: false,
            },
          ],
        },
      ],
    };
  },
};
</script>
